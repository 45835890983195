import React from "react"
import ContactSalesForm from "./forms/contact-sales-form"
import WhitePaperForm from "./forms/white-paper-form"

export default function Modals() {
    return <>
        {/*CONTACTO CON VENTAS*/}
        <div className="modal fade" id="contacto" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <div align="center" className="modal-header">
                        <h5 className="modal-title spacing" id="exampleModalLabel">
                            <span role="img" aria-label="Contacto">👉</span> Contacto con ventas
                        </h5>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-6">
                                    <ContactSalesForm />
                                </div>

                                <div className="col-xl-1" />

                                <div className="col-xl-5">
                                    <img src={"/img/contact.svg"} className="img-fluid" alt="..." />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        {/*DESCARGAR WHITE PAPER*/}
        <div className="modal fade" id="wpaper" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg modal-dialog-centered">
                <div className="modal-content">
                    <div align="center" className="modal-header">
                        <h6 className="modal-title spacing" id="exampleModalLabel"><span role="img">👉</span> Iniciar descarga White Paper</h6>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>

                    <div className="modal-body">
                        <div className="container">
                            <div className="row">
                                <div className="col-xl-6">
                                    <h6 className="text-black-80 spacing font-size-md">Un último paso antes de comenzar la descarga...</h6>
                                    <WhitePaperForm />
                                </div>

                                <div className="col-xl-1" />

                                <div className="col-xl-5">
                                    <img src={"/img/w-paper.svg"} className="img-fluid mr-4 mt-2" alt="..." />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}
