import React from "react"
import { Menu } from "react-feather"
import Link from "gatsby-link"

const Header = (props) => {
    return <nav className="navbar navbar-expand-xl navbar-light bg-white border-bottom sticky-top navigation">
        <div className="container-fluid">
            <Link className="navbar-brand mobile m--left--fluid" to={"/" + props.lang}>
                <img src={"/img/logo-signature.svg"} className="brand-logo" alt="Signature" />
            </Link>
            <Link className="navbar-brand desktop m--left--fluid" to={"/" + props.lang}>
                <img src={"/favicon.ico"} className="brand-logo-mobile" alt="Signature" />
            </Link>

            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#menuSig" aria-controls="menuSig" aria-expanded="false"
                    aria-label="Toggle navigation">
                <Menu className="icon3 text-tecalis mt-0" />
            </button>

            <div className="collapse navbar-collapse ml-0" id="menuSig">
                <ul className="navbar-nav mr-auto mb-2 mb-lg-0 menu">
                    <li className="li nav-item mr-3 first--link">
                        <Link className="nav-link spacing" to={"/" + props.lang} activeClassName="page-active"><span>Inicio</span></Link>
                    </li>
                    <li className="nav-item mr-3">
                        <Link className="nav-link spacing" to={"/" + props.lang + "/funcionalidades"} activeClassName="page-active"><span>Funcionalidades</span></Link>
                    </li>
                    <li className="nav-item mr-3">
                        <Link className="nav-link spacing" to={"/" + props.lang + "/paquetes"} activeClassName="page-active"><span>Paquetes</span></Link>
                    </li>
                    <li className="nav-item mr-3">
                        <Link className="nav-link spacing" to={"/" + props.lang + "/legalidad"} activeClassName="page-active"><span>Legalidad</span></Link>
                    </li>
                    <div className="vl--nav mobile" />
                    <li className="nav-item mr-3">
                        <Link className="nav-link spacing" to={"/" + props.lang + "/faq"} activeClassName="page-active"><span
                            className="opacity-nav">Preguntas frecuentes</span></Link>
                    </li>
                    <li className="nav-item mr-3">
                        <a className="nav-link spacing" href="https://www.tecalis.com/es" target="_blank" rel="noreferrer"><span className="opacity-nav">Sobre Tecalis</span></a>
                    </li>
                </ul>
                <div className="d-flex buttons--mobile">
                    <a href="https://tecalis.atlassian.net/servicedesk/customer/portals" target="_blank" rel="noreferrer"
                       className="btn btn-tecalis-sm-mobile-outline mr-2 spacing">
                        Asistencia
                    </a>
                    {/*<a href="" className="btn btn-tecalis-sm-mobile spacing m--right--fluid">Iniciar sesión</a>*/}
                </div>
            </div>
        </div>
    </nav>
}

export default Header
