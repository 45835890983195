import React, { useState } from "react"
import { Briefcase, Mail, Phone, User } from "react-feather"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { useFormInput } from "../../utils/helpers"


export default function ContactSalesForm() {
    const name = useFormInput("")
    const email = useFormInput("")
    const company = useFormInput("")
    const phone = useFormInput("")
    const message = useFormInput("")
    const conditions = useFormInput("")

    const { executeRecaptcha } = useGoogleReCaptcha()
    const [success, setSuccess] = useState("")
    const [fail, setFail] = useState("")

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!executeRecaptcha) {
            return
        }

        const token = await executeRecaptcha("homepage")

        const data = JSON.stringify({
            "contactSalesName": name.value,
            "contactSalesEmail": email.value,
            "contactSalesOrganization": company.value,
            "contactSalesPhone": phone.value,
            "contactSalesMessage": message.value,
            "g-recaptcha-response": token
        })
        const contactSalesForm = document.getElementById("contact-sales-form")
        const btnSend = document.getElementById("btn-contact-sales-send")

        contactSalesForm.style.opacity = "0.5"
        btnSend.innerText = "Enviando..."
        btnSend.disabled = true

        fetch(process.env.SPIRIT_URL + "contact-sales-form", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            },
            body: data
        })
            .then(response => {
                if (response.ok) return response.json()

                throw new Error("No enviado. No ha sido posible registrar tu mensaje, por favor, contáctanos de otro modo")
            })
            .then(data => {
                contactSalesForm.style.opacity = "1"
                contactSalesForm.reset()
                clearForm()
                btnSend.innerText = "Enviar"
                btnSend.disabled = false
                setSuccess(data.msg)
                setFail("")
                hideAlerts()
            })
            .catch(error => {
                contactSalesForm.style.opacity = "1"
                btnSend.innerText = "Enviar"
                btnSend.disabled = false
                setFail(error.message)
                setSuccess("")
                hideAlerts()
            })
    }

    const hideAlerts = () => {
        setTimeout(() => {
            setSuccess("")
            setFail("")
        }, 10000)
    }

    const clearForm = () => {
        name.clearValue()
        email.clearValue()
        company.clearValue()
        phone.clearValue()
        message.clearValue()
    }

    return <form id="contact-sales-form" onSubmit={handleSubmit} className="form--contact">
        <div className="form-group mb-4">
            <input type="text" id="contactSalesName" name="contactSalesName" {...name} required />
            <label htmlFor="contactSalesName" className="control-label">Nombre*</label>
            <i className="bar" />
            <span className="enter--buscar"><User className="icon" /></span>
        </div>

        <div className="form-group mb-4">
            <input type="email" id="contactSalesEmail" name="contactSalesEmail" {...email} required />
            <label htmlFor="contactSalesEmail" className="control-label">Dirección de email*</label>
            <i className="bar" />
            <span className="enter--buscar"><Mail className="icon" /></span>
        </div>

        <div className="form-group mb-4">
            <input type="text" id="contactSalesOrganization" name="contactSalesOrganization" {...company} required />
            <label htmlFor="contactSalesOrganization" className="control-label">Empresa</label>
            <i className="bar" />
            <span className="enter--buscar"><Briefcase className="icon" /></span>
        </div>

        <div className="form-group mb-4">
            <input type="text" id="contactSalesPhone" name="contactSalesPhone" {...phone} required />
            <label htmlFor="contactSalesPhone" className="control-label">Número de teléfono</label>
            <i className="bar" />
            <span className="enter--buscar"><Phone className="icon" /></span>
        </div>

        <div className="form-group mb-5">
            <textarea id="contactSalesMessage" name="contactSalesMessage" {...message} required />
            <label htmlFor="contactSalesMessage" className="control-label">Consulta*</label>
            <i className="bar" />
        </div>

        <div align="center">
            <div className="checkbox icheck-primary spacing">
                <input id="contactSalesConditions" name="contactSalesConditions" type="checkbox" required />
                <label htmlFor="contactSalesConditions">
                    He leído y acepto las <a href="https://www.tecalis.com/es/privacidad" target="_blank" rel="noreferrer" className="text-tecalis">
                    condiciones de privacidad</a> de Tecalis Signature.
                </label>
            </div>
        </div>

        <br />

        {success && <div className="alert alert-success mt-2 animate__animated animate__fadeInDown" role="alert">
            <div align="center" className="font-alert spacing">Gracias. Te responderemos lo antes posible 🙂</div>
        </div>}

        {fail && <div className="alert alert-danger mt-2 animate__animated animate__fadeInDown" role="alert">
            <div align="center" className="font-alert spacing">Se ha producido un error. Inténtalo de nuevo 😔</div>
        </div>}

        <div align="center" className="mb-4">
            <button id="btn-contact-sales-send" className="btn btn-tecalis spacing" type="submit">Enviar</button>
        </div>
    </form>
}
