import React, { useState } from "react"
import { Mail, User } from "react-feather"
import { useFormInput } from "../../utils/helpers"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"

export default function WhitePaperForm() {
    const name = useFormInput("")
    const email = useFormInput("")

    const { executeRecaptcha } = useGoogleReCaptcha()
    const [success, setSuccess] = useState("")
    const [fail, setFail] = useState("")

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!executeRecaptcha) {
            return
        }

        const token = await executeRecaptcha("homepage")

        const data = JSON.stringify({
            "whitePaperName": name.value,
            "whitePaperEmail": email.value,
            "g-recaptcha-response": token
        })
        const whitePaperForm = document.getElementById("white-paper-form")
        const btnSend = document.getElementById("btn-white-paper-send")

        whitePaperForm.style.opacity = "0.5"
        btnSend.innerText = "Enviando..."
        btnSend.disabled = true

        fetch(process.env.SPIRIT_URL + "white-paper-form", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-type": "application/json",
                "X-Requested-With": "XMLHttpRequest"
            },
            body: data
        })
            .then(response => {
                if (response.ok) return response.json()

                throw new Error("No enviado. No ha sido posible registrar tu mensaje, por favor, contáctanos de otro modo")
            })
            .then(data => {
                whitePaperForm.style.opacity = "1"
                whitePaperForm.reset()
                clearForm()
                btnSend.innerText = "Descargar"
                btnSend.disabled = false
                setSuccess(data.msg)
                setFail("")
                hideAlerts()
            })
            .catch(error => {
                whitePaperForm.style.opacity = "1"
                btnSend.innerText = "Descargar"
                btnSend.disabled = false
                setFail(error.message)
                setSuccess("")
                hideAlerts()
            })
    }

    const hideAlerts = () => {
        setTimeout(() => {
            setSuccess("")
            setFail("")
        }, 10000)
    }

    const clearForm = () => {
        name.clearValue()
        email.clearValue()
    }

    return <form onSubmit={handleSubmit} id="white-paper-form" className="form--contact">
        <div className="form-group mb-4">
            <input type="text" id="whitePaperName" name="whitePaperName" {...name} required />
            <label htmlFor="whitePaperName" className="control-label">Nombre*</label>
            <i className="bar" />
            <span className="enter--buscar"><User className="icon" /></span>
        </div>

        <div className="form-group mb-4">
            <input type="email" id="whitePaperEmail" name="whitePaperEmail" {...email} required />
            <label htmlFor="whitePaperEmail" className="control-label">Dirección de email*</label>
            <i className="bar" />
            <span className="enter--buscar"><Mail className="icon" /></span>
        </div>

        <br />

        <div align="center">
            <div className="checkbox icheck-primary spacing">
                <input type="checkbox" id="whitePaperConditions" name="whitePaperConditions" required />
                <label htmlFor="whitePaperConditions">
                    He leído y acepto las <a href="https://www.tecalis.com/es/privacidad" target="_blank" rel="noreferrer" className="text-tecalis">
                    condiciones de privacidad</a> de Tecalis Signature.
                </label>
            </div>
        </div>

        <br />

        {success && <div className="alert alert-success mt-2 animate__animated animate__fadeInDown" role="alert">
            <div align="center" className="font-alert spacing">Gracias. Te hemos enviado un correo con el link de descarga 🙂</div>
        </div>}

        {fail && <div className="alert alert-danger mt-2 animate__animated animate__fadeInDown" role="alert">
            <div align="center" className="font-alert spacing">Se ha producido un error. Inténtalo de nuevo 😔</div>
        </div>}

        <div align="center" className="mb-4">
            <button id="btn-white-paper-send" className="btn btn-tecalis spacing" type="submit">Descargar</button>
        </div>
    </form>
}
