import React from "react"
import Link from "gatsby-link"

const Top = (props) => {
    return <div className="d-block top-header">
        <div className="container">
            <div className="row">
                <div className="col-xl-12">
                    <h6 align="center">
                        Nuevas funcionalidades disponibles en Tecalis Signature &mdash; Más info <Link to={"/" + props.lang + "/funcionalidades"} className="text-white"><b>aquí</b></Link>
                    </h6>
                </div>
            </div>
        </div>
    </div>
}

export default Top
