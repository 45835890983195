import React from "react"
import { Facebook, Instagram, Linkedin, Twitter, Youtube } from "react-feather"
import Link from "gatsby-link"

const Footer = (props) => {
    return <>
        <section className="py-5 cta border-top">
            <div className="container">
                <div className="row">
                    <div className="col-12 col-xl">
                        <h3>¿Estás interesado en Tecalis Signature?</h3>
                    </div>
                    <div className="col-12 col-xl-auto">
                        <button data-toggle="modal" data-target="#contacto" className="btn btn-tecalis mt-3 spacing">
                            Contactar con ventas
                        </button>
                    </div>
                </div>
            </div>
        </section>

        <footer className="pt-10 pb-5 mt-auto bg-grey">
            <div className="container">
                <br />
                <div className="row">
                    <div className="col-12 col-xl">
                        <div className="row pt-5">
                            <div className="col-lg-4">
                                <img src={"/img/logo-signature.svg"} className="brand-logo" alt="Signature" />
                                <div className="mb-3 spacing mt-1">Electronic Signature</div>
                                <ul className="list-unstyled list-inline list-social mt-6 mb-6 mb-md-0">
                                    <li className="list-inline-item list-social-item mr-3">
                                        <a href="https://facebook.com/tecalis" target="_blank" rel="me" className="text-decoration-none">
                                            <Facebook className="icon" />
                                        </a>
                                    </li>
                                    <li className="list-inline-item list-social-item mr-3">
                                        <a href="https://twitter.com/tecalis" target="_blank" rel="me" className="text-decoration-none">
                                            <Twitter className="icon" />
                                        </a>
                                    </li>
                                    <li className="list-inline-item list-social-item mr-3">
                                        <a href="https://es.linkedin.com/company/tecalis" target="_blank" rel="me" className="text-decoration-none">
                                            <Linkedin className="icon" />
                                        </a>
                                    </li>
                                    <li className="list-inline-item list-social-item mr-3">
                                        <a href="https://www.instagram.com/tecalis" target="_blank" rel="me" className="text-decoration-none">
                                            <Instagram className="icon" />
                                        </a>
                                    </li>

                                    <li className="list-inline-item list-social-item">
                                        <a href="https://www.youtube.com/c/Tecalis" target="_blank" rel="me" className="text-decoration-none">
                                            <Youtube className="icon" />
                                        </a>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-lg-8">
                                <div className="row">
                                    <div className="col-lg-4 col-md-6 mb-5 mb-lg-0">
                                        <ul className="list-unstyled mb-0">
                                            <li className="mb-2 spacing"><Link to={"/" + props.lang}>Inicio</Link></li>
                                            <li className="mb-2 spacing"><Link to={"/" + props.lang + "/funcionalidades"}>Funcionalidades</Link></li>
                                            <li className="mb-2 spacing"><Link to={"/" + props.lang + "/paquetes"}>Paquetes</Link></li>
                                            <li className="mb-2 spacing"><Link to={"/" + props.lang + "/legalidad"}>Legalidad</Link></li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-4 col-md-6 mb-5 mb-lg-0">
                                        <ul className="list-unstyled mb-0">
                                            <li className="mb-2 spacing"><a href="https://tecalis.atlassian.net/servicedesk/customer/portals" target="_blank"
                                                                            rel="noreferrer">Asistencia</a></li>
                                            <li className="mb-2 spacing"><Link to={"/" + props.lang + "/faq"}>Preguntas frecuentes</Link></li>
                                            {/*<li className="mb-2 spacing"><a href="https://tecalis.com/es" target="_blank" rel="noreferrer">Garantía eIDAS</a></li>*/}
                                            <li className="mb-2 spacing"><a href="https://www.tecalis.com/es" target="_blank" rel="noreferrer">Sobre Tecalis</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr className="my-5" />

                        <div className="row align-items-center">
                            <div className="col-md-6 small spacing">Copyright &#xA9; Tecalis Signature 2020</div>
                            <div className="col-md-6 text-md-right small">
                                <a href="https://www.tecalis.com/es/legal" target="_blank" rel="noreferrer" className="spacing">Términos y condiciones</a>
                                <span> &mdash; </span>
                                <a href="https://www.tecalis.com/es/privacidad" target="_blank" rel="noreferrer" className="spacing">Aviso de privacidad</a>
                            </div>
                        </div>
                    </div>

                    <div className="col-12 col-xl-auto">
                        <div align="center">
                            <a href="https://www.tecalis.com/es/responsabilidad-social" target="_blank" rel="noreferrer">
                                <img src={"/img/rst.png"} className="img-fluid mt-5 mb-3 opacity--img" width="100" alt="..." />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    </>
}

export default Footer
