import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"

function SEO({ description, lang, meta, title, image, schemaMarkup }) {
    const { site } = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
    )

    const metaDescription = description || site.siteMetadata.description
    const metaImage = image || site.siteMetadata.image

    return (
        <Helmet
            htmlAttributes={{
                lang
            }}
            title={title}
            titleTemplate={`%s - ${site.siteMetadata.title}`}
            meta={[
                {
                    name: `description`,
                    content: metaDescription
                },
                {
                    property: `og:title`,
                    content: title
                },
                {
                    property: `og:description`,
                    content: metaDescription
                },
                {
                    property: `og:image`,
                    content: "https://www.tecalis.com" + metaImage
                },
                {
                    property: `og:type`,
                    content: `website`
                },
                {
                    name: `twitter:card`,
                    content: `summary`
                },
                {
                    name: `twitter:creator`,
                    content: ""
                },
                {
                    name: `twitter:title`,
                    content: title
                },
                {
                    name: `twitter:description`,
                    content: metaDescription
                },
                {
                    property: `twitter:image`,
                    content: "https://www.tecalis.com" + metaImage
                }
            ].concat(meta)}
        >

            {schemaMarkup &&
            <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script>
            }
            {metaImage &&
            <link rel="image_src" href={"https://www.tecalis.com" + metaImage} />
            }
        </Helmet>
    )
}

SEO.defaultProps = {
    lang: "es",
    meta: [],
    description: "",
    title: "Tecalis Signature",
    image: require("../../static/favicon.ico")
}

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
    image: PropTypes.string
}

export default SEO
