import React from "react"
import { Helmet } from "react-helmet"
import SEO from "../components/seo"
import Footer from "../components/footer"
import Modals from "../components/modals"
import Top from "../components/top"
import Header from "../components/header"
import PropTypes from "prop-types"
import { graphql, StaticQuery } from "gatsby"
import { getCurrentLangKey, getLangs, getUrlForLang } from "ptz-i18n"
import { IntlProvider } from "react-intl"
import "intl"

const Layout = ({ children, location, i18nMessages }) => {
    return (
        <StaticQuery
            query={graphql`
                query LayoutQuery {
                    site {
                        siteMetadata {
                            languages {
                                defaultLangKey
                                langs
                            }      
                        }
                    }
                }
            `}
            render={data => {
                const url = location.pathname
                const { langs, defaultLangKey } = data.site.siteMetadata.languages
                const langKey = getCurrentLangKey(langs, defaultLangKey, url)
                const homeLink = `/${langKey}/`.replace(`/${defaultLangKey}/`, '/');
                const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url)).map((item) => ({ ...item, link: item.link.replace(`/${defaultLangKey}/`, '/') }));

                return (
                    <IntlProvider locale={langKey} messages={i18nMessages}>
                        <div>
                            <Helmet>
                                <link rel="stylesheet" href="https://use.typekit.net/acr2trl.css" />

                                <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.0/dist/umd/popper.min.js"
                                        integrity="sha384-Q6E9RHvbIyZFJoft+2mJbHaEWldlvI9IOYy5n3zV9zzTtmI3UksdQRVvoxMfooAo"
                                        crossOrigin="anonymous" />
                                <script src="https://stackpath.bootstrapcdn.com/bootstrap/5.0.0-alpha1/js/bootstrap.min.js"
                                        integrity="sha384-oesi62hOLfzrys4LxRF63OJCXdXDipiYWBnvTl9Y9/TRlw5xlKIEHpNyvvDShgf/"
                                        crossOrigin="anonymous" />
                                <script src="https://cdn.jsdelivr.net/gh/cferdinandi/smooth-scroll@15.0/dist/smooth-scroll.polyfills.min.js" />
                                <script src={"/scripts/custom.js"} />
                            </Helmet>

                            <SEO />

                            <Top lang={langKey} />
                            <Header lang={langKey} />

                            <main>
                                {children}
                            </main>

                            <Footer lang={langKey} />

                            <Modals />
                        </div>
                    </IntlProvider>
                )
            }}
        >
        </StaticQuery>
    )
}

Layout.propTypes = {
    children: PropTypes.func
}

export default Layout
